import React from 'react';
import { Typography, message, Form, Row, Col, Button } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { UsernameInput } from 'features/components';
import { editMobileUser } from '../../api/editMobileUser';

function UpdateUser({ userData = {} }) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const error = {};
  const isLoading = false;

  const editUserMutation = useMutation((formData) => editMobileUser(formData), {
    onMutate: () => {
      message.loading({ content: 'Editing user profile', key: 'editUser', duration: 120 });
    },
    onSuccess: () => {
      message.success({
        content: 'Editing user profile successful',
        key: 'editUser',
        duration: 5,
      });

      queryClient.invalidateQueries(['User v2', { id: userData.id }]);
    },
    onError: () => {
      message.error({
        content: 'Editing user profile failed',
        key: 'editUser',
        duration: 5,
      });

      queryClient.invalidateQueries(['User v2', { id: userData.id }]);
    },
  });

  const onResetHandler = () => {
    form.resetFields();
  };

  const onFinishHandler = (formValues) => {
    editUserMutation.mutate({ ...formValues, userId: userData.id });
  };

  return (
    <Form form={form} layout="vertical" name="signUp" onFinish={onFinishHandler}>
      <Typography.Title level={3}>Edit user profile:</Typography.Title>
      <Form.Item
        label="Username:"
        name="userName"
        rules={[
          { required: true, message: '*username is required field' },
          {
            validator: (_, value) => {
              if (value) {
                const regex = /^[\w\-~]*$/;
                if (!regex.test(value)) {
                  return Promise.reject(
                    new Error(
                      'Username can contain uppercase, lowercase, numbers and following special characters -_~'
                    )
                  );
                }
              }
              return Promise.resolve();
            },
          },
          {
            validator: () => {
              if (error.username) {
                return Promise.reject(
                  new Error('This username is already taken, please choose another username.')
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        {...(editUserMutation.isError && {
          help: editUserMutation.error?.message,
          validateStatus: 'error',
        })}
      >
        <UsernameInput autoComplete="username-edit" placeholder={userData.username} />
      </Form.Item>

      <Row align="middle" justify="center">
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Edit User
            </Button>
          </Form.Item>
        </Col>
        <Col style={{ width: '3rem' }} />
        <Col>
          <Form.Item>
            <Button type="secondary" onClick={onResetHandler}>
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

UpdateUser.propTypes = {
  userData: PropTypes.object,
};

export default UpdateUser;
